import { gql } from "@/__generated__";

const accountsQuery = gql(`
  query AuthAccounts {
    accounts {
      __typename
      id
      comverseAccountId
      comverseServiceId
      phoneNumber
      type
      disconnected
    }
  }
`);

const removeAccountMutation = gql(`
mutation RemoveAccount($comverseAccountId: ComverseId!) {
  deleteAccount(comverseAccountId: $comverseAccountId) {
    __typename
    ... on DeleteAccountSuccess {
      __typename
      isSuccess
      message
    }
    ... on DeleteAccountProblem {
      __typename
      isSuccess
      message
      invalidInputs {
        field
        message
      }
    }
  }
}
`);

const servicesQuery = gql(`
  query AuthServices($comverseAccountId: ComverseId!) {
    services(comverseAccountId: $comverseAccountId) {
      __typename
      id
      phoneNumber
      comverseAccountId
      comverseServiceId
    }
  }
`);

export { accountsQuery, removeAccountMutation, servicesQuery };
