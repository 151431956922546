import { useEffect, useState, useRef } from "react";
import Script from "next/script";
import useAuth from "@/useAuth";
import config from "@/config";
import { formatUndefinedAsEmptyString } from "@/utils";
import UserAccountMachineContext from "@/components/machines/userAccountMachine/UserAccountMachineContext";
import useSelectedServiceType from "@/components/machines/userAccountMachine/useSelectedServiceType";

interface UserData {
  data: string;
  label: string;
  name: string;
  type: string;
}

declare global {
  interface Window {
    talkativeApi: {
      ui: {
        activate: () => void;
        deactivate: () => void;
        hide: () => void;
        isVisible: () => boolean;
        show: () => void;
      };
      interactionData: {
        appendInteractionData: (data: Array<UserData>) => void;
      };
    };
    talkativeCustomConfig: {
      events: {
        enterStandby: () => void;
        ready: () => void;
        visibilityChange: (props: { visibility: boolean }) => void;
      };
    };
  }
}

function Chat() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [
    isChatReadyAndShouldShowMobileButton,
    setIsChatReadyAndShouldShowMobileButton,
  ] = useState(false);
  const serviceType = useSelectedServiceType();
  const userState = UserAccountMachineContext.useSelector((snapshot) => {
    return snapshot.context;
  });
  const { selectedComverseAccountId, selectedService } = userState;
  const { user } = useAuth();
  const userDataReference = useRef<Array<UserData>>([]);

  function toggleChatFloatButtonOrEdgeButton() {
    if (window.innerWidth > 767) {
      // desktop show Chat Float Button
      window.talkativeApi.ui.activate();
    } else if (!window.talkativeApi.ui.isVisible()) {
      // mobile only show Chat Edge Button when chat is not open
      window.talkativeApi.ui.deactivate();
    }
  }

  function setTalkativeInteractionData() {
    if (
      user &&
      selectedService !== undefined &&
      selectedComverseAccountId !== undefined
    ) {
      const prefilledUserData = [
        {
          data: user.username,
          label: "Email",
          name: "email",
          type: "string",
        },
      ];

      if (serviceType === "Prepaid" && selectedService.phoneNumber) {
        prefilledUserData.push({
          data: selectedService.phoneNumber,
          label: "Phone Number",
          name: "phoneNumber",
          type: "string",
        });
      } else if (serviceType === "Postpaid" && selectedComverseAccountId) {
        prefilledUserData.push({
          data: selectedComverseAccountId,
          label: "Account Number",
          name: "accountNumber",
          type: "string",
        });
      }

      const displayName = [
        formatUndefinedAsEmptyString(user.idTokenClaims?.given_name as string),
        formatUndefinedAsEmptyString(user.idTokenClaims?.family_name as string),
      ]
        .join(" ")
        .trim();

      if (displayName !== "") {
        prefilledUserData.push({
          data: displayName,
          label: "Name",
          name: "name",
          type: "string",
        });
      }
      userDataReference.current = prefilledUserData;
    }
  }

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      toggleChatFloatButtonOrEdgeButton();
    });

    // reference: https://github.com/talkative-tech/widget-examples/blob/main/event-hooks/main.js
    window.talkativeCustomConfig = {
      events: {
        enterStandby: () => {
          window.talkativeApi.interactionData.appendInteractionData(
            userDataReference.current,
          );
        },
        ready: () => {
          setIsChatReadyAndShouldShowMobileButton(true);
          toggleChatFloatButtonOrEdgeButton();
          resizeObserver.observe(document.body);
        },
        visibilityChange: ({
          visibility: isVisible,
        }: {
          visibility: boolean;
        }) => {
          // This event is triggered when the chat widget is hidden or shown. ie, when the chat button is clicked.
          if (isVisible) {
            setIsChatOpen(true);
          } else {
            setIsChatOpen(false);
          }
        },
      },
    };
  }, []);

  useEffect(() => {
    if (typeof window.talkativeApi === "object") {
      if (isChatOpen) {
        window.talkativeApi.ui.activate();
        window.talkativeApi.ui.show();
      } else {
        if (window.innerWidth <= 767) {
          window.talkativeApi.ui.deactivate();
        }
        window.talkativeApi.ui.hide();
      }
    }
  }, [isChatOpen]);

  useEffect(() => {
    if (isChatReadyAndShouldShowMobileButton) {
      setTalkativeInteractionData();
    }
  }, [
    isChatReadyAndShouldShowMobileButton,
    selectedService,
    selectedComverseAccountId,
  ]);

  return (
    <>
      <Script
        src={`https://eu.engage.app/api/ecs/v1/loader/${config.otherThirdParties.talkativeWidgetId}.js?path=${encodeURIComponent(window.location.origin.replace("capacitor", "https") + window.location.pathname)}&selectedVersion=${new URLSearchParams(window.location.search).get("ecsSelectedVersion") ?? ""}`}
      />
      {isChatReadyAndShouldShowMobileButton && !isChatOpen ? (
        <div
          aria-hidden="true"
          className="rounded-t-lg bg-primary px-4 py-1 sm:px-8 md:hidden"
          id="buttonChat"
          onClick={() => {
            setIsChatOpen(!isChatOpen);
          }}
        >
          Live Chat
        </div>
      ) : null}
    </>
  );
}

export default Chat;
